<template>
  <v-container fluid>
    <v-layout row wrap class="justify-center" style="margin: 0px !important">
      <v-flex
        xs12
        sm12
        md12
        style="padding: 5px"
        v-if="pageSelected != 'Whatsapp'"
      >
        <!-- <v-card elevation="0">
          <v-card-text style="padding: 6px">
            <v-breadcrumbs :items="['Dashboard', 'Settings', 'Templates', 'Preview']">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item style="font-size: 12px">
                  {{ item }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-card-text>
        </v-card> -->
        <Breadcrumbs />
      </v-flex>
      <v-flex xs12 sm10>
        <v-card class="ma-3 rounded">
          <v-card-text>
            <div
              v-if="preloader"
              style="text-align: center; padding: 25px"
              align="center"
            >
              <v-row
                class="fill-height"
                align-content="center"
                justify="center"
              >
                <v-col class="subtitle-1 text-center" cols="12">
                  Please Wait
                </v-col>
                <v-col cols="6">
                  <v-progress-linear
                    color="primary"
                    indeterminate
                    rounded
                    height="6"
                  ></v-progress-linear>
                </v-col>
              </v-row>
            </div>
            <div v-else-if="Object.keys(template).length > 0" class="pa-md-3">
              <h2 class="font-weight-medium text-center">Preview</h2>
              <v-text-field
                v-model="template.title"
                prepend-inner-icon="post_add"
                placeholder="Title"
                messages="Name your template"
                class="pt-5 px-md-5"
                solo-inverted
                flat
                dense
                clearable
              >
              </v-text-field>
              <v-text-field
                v-model="template.desc"
                prepend-inner-icon="description"
                clearable
                solo-inverted
                flat
                class="pt-5 px-md-5"
                placeholder="Description"
                dense
              >
              </v-text-field>
              <h3 class="px-5 font-weight-regular">Choose a template type</h3>
              <v-radio-group
                v-model="template.type"
                class="justify-center"
                row
                align="center"
                @change="selectType"
              >
                <v-radio
                  v-for="(type, idx) in types"
                  :key="idx"
                  color="primary"
                  :label="type.text"
                  :value="type.name"
                  class="ma-3 pa-3"
                  style="border: 1px solid #a8a4a4; border-radius: 4px"
                >
                </v-radio>
              </v-radio-group>
              <!-- <v-row style="margin: 0px; padding: 20px 20px 10px 20px">
                  <v-flex
                    xs12
                    sm8
                    offset-sm2
                    md8
                    offset-md2
                    style="padding: 5px 5px"
                  >
                    
                  </v-flex>
                  <v-flex
                    xs12
                    sm8
                    offset-sm2
                    md8
                    offset-md2
                  >
                    
                    !-- <v-row align="center">
                      <div style="text-align:center;" class="mx-auto">
                        <v-chip
                          v-for="(type, idx) in types"
                          :key="idx"
                          :color="type.selected ? 'green' : '#d3d3d3'"
                          style="margin: 0px 7px 7px"
                          @click="selectType(type.name)"
                        >
                          <v-icon
                            left
                            :color="type.selected ? '#d3d3d3' : 'green'"
                            >{{ type.icon }}</v-icon
                          >{{ type.text }}
                        </v-chip>
                      </div>
                    </v-row> --
                    <div align="center">
                      
                    </div>
                  </v-flex>
                </v-row> -->
              <v-row
                v-if="
                  template.type &&
                    (template.type == 'image' || template.type == 'both')
                "
                style="margin: 0px; padding: 20px 20px 10px 20px"
              >
                <v-flex xs12 sm8 offset-sm2 md8 offset-md2>
                  <v-card
                    style="opacity:0.56;min-height:80px;background-color:#d3d3d3;border:2px dashed black"
                  >
                    <div
                      class="flex w-full h-screen items-center justify-center text-center"
                    >
                      <div
                        class="p-12 bg-gray-100 border border-gray-300"
                        @dragover="dragover"
                        @dragleave="dragleave"
                        @drop="drop"
                      >
                        <input
                          id="assetsFieldHandle"
                          ref="file"
                          type="file"
                          multiple
                          name="fields[assetsFieldHandle][]"
                          class="w-px h-px opacity-0 overflow-hidden absolute"
                          accept=".jpg,.jpeg,.png"
                          style="display:none"
                          @change="onChange"
                        />

                        <label
                          for="assetsFieldHandle"
                          class="block cursor-pointer mt-2"
                        >
                          <div style="padding-top:23px">
                            Drop a file or
                            <span class="underline">click here</span> to upload
                            file
                          </div>
                        </label>
                        <ul
                          v-if="this.filelist.length"
                          v-cloak
                          style="list-style-type:none;"
                          class="mt-4"
                        >
                          <li
                            v-for="(file, i) in filelist"
                            :key="i"
                            class="text-sm p-1"
                          >
                            {{ file.name }}
                            <button
                              class="ml-2"
                              type="button"
                              title="Remove file"
                              @click="remove()"
                            >
                              x
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </v-card>

                  <v-card v-if="attachmentURL" class="mt-4">
                    <v-container></v-container>
                    <span class=" pl-5 pt-3"
                      >Image Preview
                      <button
                        style="float:right;"
                        class="pr-5"
                        type="button"
                        title="Remove file"
                        @click="remove()"
                      >
                        x
                      </button>
                    </span>
                    <v-img
                      rounded
                      class="ma-3"
                      :src="attachmentURL"
                      style="border-radius:9px;"
                    ></v-img>
                    <v-container></v-container>
                  </v-card>
                </v-flex>
              </v-row>
              <div
                v-if="
                  typeof template.type == 'undefined' ||
                    (template.type &&
                      (template.type == 'text' || template.type == 'both'))
                "
              >
                <v-row class="pa-0 ma-0">
                  <v-flex class="px-md-10">
                    <v-card class="secondarygrad mx-md-15">
                      <v-card-text style="text-align: right">
                        {{ compileText(template.text) }}
                      </v-card-text>
                      <v-card-actions style="text-align: right">
                        <v-spacer></v-spacer>
                        <p style="margin: 5px; font-size: 13px">
                          {{ $moment().format("LLLL") }}
                        </p>
                      </v-card-actions>
                    </v-card>
                  </v-flex>
                </v-row>
              </div>
              <v-row style="margin: 0px; padding: 20px">
                <v-flex
                  xs12
                  sm8
                  offset-sm2
                  md8
                  offset-md2
                  style="padding: 0px 5px"
                >
                  <div
                    v-if="
                      typeof template.type == 'undefined' ||
                        (template.type &&
                          (template.type == 'text' ||
                            template.type == 'both' ||
                            template.type == 'link'))
                    "
                  >
                    <v-row style="margin: 0px 0px 15px 0px">
                      <v-flex xs12 sm8 md8>
                        <v-select
                          v-model="selectedField"
                          prepend-inner-icon="input"
                          outlined
                          dense
                          :items="fields"
                        ></v-select>
                      </v-flex>
                      <v-flex xs12 sm4 md4 class="px-md-2">
                        <v-btn
                          block
                          color="primarygrad"
                          dark
                          @click="customValue"
                          >Add to Text</v-btn
                        >
                      </v-flex>
                    </v-row>
                  </div>
                </v-flex>

                <v-flex
                  xs12
                  sm8
                  offset-sm2
                  md8
                  offset-md2
                  style="padding: 0px 5px"
                >
                  <div
                    v-if="
                      typeof template.type == 'undefined' ||
                        (template.type &&
                          (template.type == 'text' || template.type == 'both'))
                    "
                  >
                    <v-textarea
                      id="templateArea"
                      ref="templateArea"
                      v-model="template.text"
                      placeholder="Enter the text, feel free to add variables from the above box."
                      prepend-inner-icon="text_format"
                      outlined
                      messages="Enter the text, feel free to add variables from the above
                        box."
                    ></v-textarea>
                  </div>
                </v-flex>

                <v-flex
                  xs12
                  sm8
                  offset-sm2
                  md8
                  offset-md2
                  style="padding: 0px 5px"
                >
                  <div v-if="template.type == 'link'">
                    <v-text-field
                      id="linkArea"
                      ref="linkArea"
                      v-model="template.text"
                      prepend-inner-icon="mdi-link"
                      solo-inverted
                      flat
                      clearable
                      placeholder="link"
                      dense
                    ></v-text-field>
                    <!-- v-on="decode()" -->
                    <!-- <v-textarea
                        placeholder="Enter the text, feel free to add variables from the above box."
                        ref="templateArea"
                        id="templateArea"
                        prepend-icon="text_format"
                        v-model="template.text"
                        outlined
                        messages="Enter the text, feel free to add variables from the above
                        box."
                      ></v-textarea> -->
                  </div>
                </v-flex>

                <v-flex
                  xs12
                  sm8
                  offset-sm2
                  md8
                  offset-md2
                  style="padding: 0px 5px"
                >
                  <v-row style="margin: 0px">
                    <v-flex
                      v-if="updateId != 'add'"
                      style="padding: 0px 0px 0px 5px; margin: 0px"
                    >
                      <v-btn
                        block
                        color="error"
                        @click.native="deleteTemplate(updateId)"
                      >
                        Remove</v-btn
                      >
                    </v-flex>
                    <v-flex style="padding: 0px 0px 0px 5px; margin: 0px">
                      <v-btn
                        block
                        color="primarygrad"
                        dark
                        @click.native="save"
                      >
                        Save</v-btn
                      >
                    </v-flex>
                  </v-row>
                </v-flex>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog v-model="loader" persistent width="300">
      <v-card color="primary white--text" dark>
        <v-card-text style="padding: 18px 25px">
          <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px">
            Please Wait...
          </h3>
          <div style="text-align:center">
            <v-progress-circular
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-circular>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar"
      :vertical="true"
      :color="color"
      multi-line
      :transition="true"
    >
      {{ text }}
      <v-btn color="white" text @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import compress from "compress-base64";
import Breadcrumbs from "../components/breadcrumbs.vue";

export default {
  data() {
    return {
      //snackbar
      pageSelected: false,
      snackbar: false,
      text: "",
      color: "",
      updateId: null, // id recieved from path query
      preloader: false,
      selectedField: "Name",
      template: {
        text: "Hey ${name}, thank you for choosing GoDial.",
        title: "",
        desc: "",
        type: "text",
      },
      fields: [
        "Agent's Name",
        "Agent's Phone",
        "Agent's Email",
        "Name",
        "Phone",
        "Email",
        "Company",
        "Remarks",
        "Note",
      ],
      allCustomFields: "",
      uploadedPic: null,
      attachmentURL: "",
      loader: false,
      fileToUpload: null,
      types: [
        {
          text: "Text",
          name: "text",
          selected: true,
          icon: "mdi-message-text",
        },
        // {
        //   text: "Image",
        //   name: "image",
        //   selected: false,
        //   icon: "mdi-message-image",
        // },
        // {
        //   text: "Image and Text",
        //   name: "both",
        //   selected: false,
        //   icon: "mdi-image-edit",
        // },
        { text: "Link", name: "link", selected: false, icon: "mdi-link" },
      ],
      filelist: [],
      style: "",
    };
  },
  components: {
    Breadcrumbs,
  },
  computed: {
    ...mapGetters(["ENDPOINT", "fieldList"]),
  },
  mounted() {
    this.updateId = this.$router.currentRoute.params.id;

    this.pageSelected = this.$route.query.page;

    this.getAllCustomFields();
    if (this.updateId != "add" && this.updateId != "") {
      this.getTemplateBy(this.updateId);
    }
  },
  methods: {
    getAllCustomFields() {
      this.allCustomFields = [...this.fieldList];
      for (var i = 0; i < this.allCustomFields.length; i++) {
        // Datatable Header
        this.fields.push(this.allCustomFields[i].name);
      }
    },

    compileText(text) {
      // var n = text.includes("world");
      var customTextNew = text;
      if (text.includes("${name}")) {
        //  customTextNew =  customTextNew.replace("${name}", "John Doe");

        customTextNew = customTextNew.split("${name}").join("John Doe");
      }

      if (text.includes("${phone}")) {
        // customTextNew =  customTextNew.replace("${phone}", "9999900000");

        customTextNew = customTextNew.split("${phone}").join("9999900000");
      }

      if (text.includes("${email}")) {
        // customTextNew =  customTextNew.replace("${email}", "support@godial.cc");

        customTextNew = customTextNew
          .split("${email}")
          .join("support@godial.cc");
      }

      if (text.includes("${companyName}")) {
        //customTextNew = customTextNew.replace("${companyName}", "Godial");

        customTextNew = customTextNew.split("${companyName}").join("Godial");
      }

      if (text.includes("${remarks}")) {
        //customTextNew = customTextNew.replace("${remarks}", "Love this application");

        customTextNew = customTextNew
          .split("${remarks}")
          .join("Love this application");
      }

      return customTextNew;
    },
    customValue() {
      var data = "";
      // Agents information
      if (this.selectedField == "Agent's Name") {
        data = "${agentName}";
      }
      if (this.selectedField == "Agent's Phone") {
        data = "${agentPhone}";
      }
      if (this.selectedField == "Agent's Email") {
        data = "${agentEmail}";
      }

      if (this.selectedField == "Phone") {
        data = "${phone}";
      }
      if (this.selectedField == "Name") {
        data = "${name}";
      }
      if (this.selectedField == "Email") {
        data = "${email}";
      }
      if (this.selectedField == "Remarks") {
        data = "${remarks}";
      }
      if (this.selectedField == "Company") {
        data = "${companyName}";
      }

      if (this.selectedField == "Note") {
        data = "${note}";
      }

      // Check & split
      var singleField = _.find(this.allCustomFields, (field) => {
        return field.name.toLowerCase() == this.selectedField.toLowerCase();
      });
      if (singleField) {
        data = "${" + `${singleField.name.toLowerCase()}` + "}";
      }

      this.insertAtCaret(data);
    },

    insertAtCaret(text) {
      var txtarea = "";
      if (this.template.type == "link") {
        txtarea = this.$refs.linkArea.$refs.input;
      } else {
        txtarea = this.$refs.templateArea.$refs.input;
      }

      var scrollPos = txtarea.scrollTop;
      var strPos = 0;
      var br =
        txtarea.selectionStart || txtarea.selectionStart == "0"
          ? "ff"
          : document.selection
          ? "ie"
          : false;

      if (br == "ie") {
        txtarea.focus();
        var range = document.selection.createRange();
        range.moveStart("character", -txtarea.value.length);
        strPos = range.text.length;
      } else if (br == "ff") strPos = txtarea.selectionStart;
      var front = txtarea.value.substring(0, strPos);
      var back = txtarea.value.substring(strPos, txtarea.value.length);
      txtarea.value = front + text + back;

      this.template.text = front + text + back;

      strPos = strPos + text.length;
      if (br == "ie") {
        txtarea.focus();
        range = document.selection.createRange();
        range.moveStart("character", -txtarea.value.length);
        range.moveStart("character", strPos);
        range.moveEnd("character", 0);
        range.select();
      } else if (br == "ff") {
        txtarea.selectionStart = strPos;
        txtarea.selectionEnd = strPos;
        txtarea.focus();
      }
      txtarea.scrollTop = scrollPos;
    },

    // Get all templates
    getTemplateBy(id) {
      this.preloader = true;
      this.template = {};

      this.$http
        .get(`${this.$store.state.ENDPOINT}/templates/${id}`)
        .then((response) => {
          this.template = response.body;
          this.gotType(this.template.type);
          if (typeof this.template.isLink != "undefined") {
            if (this.template.isLink) {
              this.gotType("link");
            } else {
              this.gotType("text");
            }
            delete this.template.isLink;
          }
          if (this.template.att) {
            this.getImage(this.template.att);
          }
          this.preloader = false;
        })
        .catch((e) => {
          this.preloader = false;
          if (e.body.error && e.body.error.message) {
            this.displayMessage({
              type: "error",
              text: e.body.error.message,
            });
            return;
          }
          this.displayMessage({
            type: "error",
            text: "Something went wrong while fetching template data.",
          });
        });
    },
    // Save and Update Template Data
    async save() {
      this.loader = true;
      var payload = this.template;
      payload.companyId = this.$store.getters.user.companyId.toString();

      //validations
      if (payload.title == "") {
        this.displayMessage({
          text: "Template Name is blank",
          type: "warning",
        });
        this.loader = false;
        return;
      }

      if (
        payload.type != "image" &&
        (payload.text == null || payload.text == "")
      ) {
        this.displayMessage({
          text: "Template Text is blank",
          type: "warning",
        });
        this.loader = false;
        return;
      }
      //********lINK */ //LATER
      // if ( payload.type == "link" ) {
      //   var lastIndex= payload.text.lastIndexOf('?');
      //   var sub = payload.text.substr(lastIndex+1);
      //   var parts = JSON.parse('{"' + decodeURIComponent(sub).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
      //   //console.log("parts ", parts);
      //   var params=""
      //   for (var key in parts) {
      //     if (parts.hasOwnProperty(key)) {
      //       parts[key] = encodeURIComponent(decodeURIComponent(parts[key]));
      //       params+='&'+key+'='+parts[key];
      //       //console.log(key + " -> " + parts[key]);
      //     }
      //   }
      //   payload.text = payload.text.substr(0,lastIndex+1)+params;
      //   //console.log("encoded text ", payload.text);
      // }
      //********lINK */
      var formData = null;
      var config = {};
      var attachment = {};

      if (typeof this.updateId != "undefined" && this.updateId == "add") {
        if (this.template.type == "text" || this.template.type == "link") {
          payload.att = null;
          payload.attId = null;
        } else {
          if (this.attachmentURL && this.fileToUpload) {
            formData = new FormData();
            formData.append("file", this.fileToUpload);
            formData.append("type", "template");
            formData.append("companyId", this.$store.state.companyDetails.id);
            config = {
              headers: {
                "Content-Type": "multipart/form-data",
                authorization: this.$store.state.user.token,
              },
            };
            attachment = await this.$http.post(
              `${this.$store.state.ENDPOINT}/attachments/upload`,
              formData,
              config
            );
            attachment = attachment.body;
            payload.attId = attachment.id;
            payload.att = attachment.name;
          }
        }
        if (payload.type == "image") {
          payload.text = "";
        }

        if (this.pageSelected == "Whatsapp") {
          payload.templateType = "Whatsapp";
        }

        this.$http
          .post(`${this.$store.state.ENDPOINT}/templates`, payload)
          .then((response) => {
            this.displayMessage({
              type: "success",
              text: "Template saved successfully",
            });
            this.loader = false;
            this.$router.replace("/templates");

            this.$swal({
              type: "success",
              text:
                "Please inform all app users to logout and login, to see the new changes.",
            });
          })
          .catch((e) => {
            this.displayMessage({
              type: "error",
              text: "Something went wrong while saving the changes",
            });
            this.loader = false;
          });
      } else {
        if (this.template.type == "text" || this.template.type == "link") {
          payload.att = null;
          payload.attId = null;
        } else {
          //attachment Removed
          if (this.attachmentURL == "") {
            if (this.template.attId) {
              try {
                await this.$http.delete(
                  `${this.$store.state.ENDPOINT}/attachments/${this.template.attId}`
                );
              } catch (e) {
                console.log(e);
              }
            }
            payload.att = "";
            payload.attId = null;
          }
          //attachment Updated
          if (this.fileToUpload) {
            if (this.template.attId) {
              try {
                await this.$http.delete(
                  `${this.$store.state.ENDPOINT}/attachments/${this.template.attId}`
                );
              } catch (e) {
                console.log(e);
              }
            }
            formData = new FormData();
            formData.append("file", this.fileToUpload);
            formData.append("type", "template");
            formData.append("companyId", this.$store.state.companyDetails.id);
            config = {
              headers: {
                "Content-Type": "multipart/form-data",
                authorization: this.$store.state.user.token,
              },
            };
            attachment = await this.$http.post(
              `${this.$store.state.ENDPOINT}/attachments/upload`,
              formData,
              config
            );
            attachment = attachment.body;
            payload.attId = attachment.id;
            payload.att = attachment.name;
          }
        }
        payload.modifiedOn = new Date().toJSON();

        if (this.pageSelected == "Whatsapp") {
          payload.templateType = "Whatsapp";
        }

        if (this.template.type == "image") {
          this.template.text = "";
        }
        this.$http
          .put(`${this.$store.state.ENDPOINT}/templates/${payload.id}`, payload)
          .then((response) => {
            this.displayMessage({
              text: "Template saved successfully",
              type: "warning",
            });
            this.loader = false;
            this.$router.replace({ path: "/templates" });

            this.$swal({
              type: "success",
              text:
                "Please inform all app users to logout and login, to see the new changes.",
            });
          })
          .catch((e) => {
            this.displayMessage({
              type: "error",
              text: "Something went wrong while saving the changes",
            });
            this.loader = false;
          });
      }
    },
    // Delete
    async deleteTemplate(id) {
      try {
        if (this.template.attId) {
          try {
            await this.$http.delete(
              `${this.$store.state.ENDPOINT}/attachments/${this.template.attId}`
            );
          } catch (e) {
            console.log(e);
          }
        }
        var response = await this.$http.delete(
          `${this.$store.state.ENDPOINT}/templates/${id}`
        );

        this.$swal({
          type: "success",
          text:
            "Please inform all app users to logout and login, to see the new changes.",
        });

        this.$router.replace({ path: "/templates" });
      } catch (e) {
        if (e.body.error && e.body.error.message) {
          //open snakbar to show errors
          this.displayMessage({ text: e.body.error.message, type: "warning" });
          return;
        }
        this.displayMessage({
          text: "Something went wrong while deleting this template",
          type: "warning",
        });
      }
    },
    // display the message
    displayMessage({ text, type }) {
      this.snackbar = false;
      var color;
      switch (type) {
        case "success":
          color = "#007E33";
          break;
        case "warning":
          color = "#FF8800";
          break;
        case "error":
          color = "#CC0000";
          break;
      }
      this.color = color;
      this.text = text;
      this.snackbar = true;
    },

    async getImage(src) {
      if (src) {
        this.attachmentURL = `${this.$store.state.ENDPOINT}/storages/get/${src}?access_token=${this.$store.getters.user.token}`;
      }
    },

    async onFileSelect(file) {
      const compressor = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (event) => {
            compress(event.target.result, {
              width: 512,
              type: "image/png", // default
              max: 2048, // max size
              min: 2, // min size
              quality: 0.5,
            })
              .then((result) => {
                resolve(result);
              })
              .catch((e) => {
                this.loader = false;
                console.log("err", e);
              });
          };
          reader.onerror = (error) => reject(error);
        });

      function srcToFile(src, fileName, mimeType) {
        return fetch(src)
          .then(function(res) {
            return res.arrayBuffer();
          })
          .then(function(buf) {
            return new File([buf], fileName, { type: mimeType });
          });
      }

      const self = this;
      self.fileName = file.name;
      self.mimeType = file.type;
      self.loader = true;

      var compressed = await compressor(file);
      self.attachmentURL = compressed;
      srcToFile(self.attachmentURL, self.fileName, self.mimeType).then(
        async (file2Upload) => {
          self.fileToUpload = file2Upload;
          self.loader = false;
        }
      );
    },
    async removeImage() {
      this.attachmentURL = "";
      // this.template.att = null;
      // this.template.attId = null;
    },

    selectType() {
      if (this.template.type == "link") {
        this.template.text = "https://yourlink.com?phone=${phone}";
      } else {
        this.template.text = "Hey ${name}, thank you for choosing GoDial.";
      }
    },
    gotType(type) {
      this.template.type = type;
      for (var each in this.types) {
        if (this.types[each].name != type) {
          this.types[each].selected = false;
        } else {
          this.types[each].selected = true;
        }
      }
    },
    onChange() {
      this.filelist = [...this.$refs.file.files];
      this.onFileSelect(this.filelist[0]);
    },
    remove() {
      this.filelist = [];
      this.attachmentURL = "";
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    // decode()
    // {
    //   try
    //   {
    //     if(this.updateId != "add")
    //   {
    //     this.template.text=decodeURIComponent(this.template.text);
    //   }
    //   //console.log(decodeURIComponent(this.template.text));
    // }
    // catch(e)
    // {
    //   console.log("error", e)
    // }
    // }
  },
};
</script>

<style scoped>
[v-cloak] {
  display: none;
}
</style>
